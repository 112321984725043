import i18n from 'i18next'
import detector from 'i18next-browser-languagedetector'
import backend from 'i18next-xhr-backend'
import { initReactI18next } from 'react-i18next'
import enJSON from 'src/locales/en'
import { LANGUAGES } from 'src/utils/locales'

i18n
  .use(detector)
  .use(backend)
  .use(initReactI18next)
  .init({
    resources: {
      en: { translation: enJSON }
    },
    lng: LANGUAGES.en,
    fallbackLng: LANGUAGES.en,
    interpolation: {
      escapeValue: false
    }
  })

export default i18n
